// Card Styles
.card-event {
    border-color: $primary;
    padding: 10px;
    margin: 10px;

    .card-title {
        font-size: 32px;
        font-weight: bold;
    }

    .card-subtitle {
        color: rgba(0, 0, 0, 0.5);
    }

    img {
        margin-bottom: 10px;
    }
}