.news-item {
  position: relative;

  .body {
    position: absolute;
    right: 50px;
    width: 300px;
    max-width: 80%;
    bottom: 0;
    background-color: white;
    padding: .2em .75em;

    .date {
      display: inline-block;
      padding: .2em .75em;
      background-color: #ffed00;
      border-radius: 10px;
      font-weight: 700;
      font-size: .7em;
    }

    h2 {
      margin: .2em 0;
    }

    a {
      text-transform: uppercase;
    }
  }
}

.news-item-section {
  padding: 0;
  margin-top: -100px;

  @media (max-width: 991px) {
    margin-top: 0;
    padding: 60px 0;
  }


  .container {
    background-color: #fff;
    position: relative;
    padding: 20px;

    .date {
        display: block;
        padding: .2em .75em;
        background-color: #ffed00;
        border-radius: 10px;
        font-weight: 700;
        font-size: .7em;
        float: right;
      }
  }
}

#nieuws.page-section {
  padding-bottom: 100px;
}